#firstview{
	$height: 650px;
	$sp-height: calc(100vh - #{$sp-head} - 48px);
	width: 100%;
	height: $height;
	background: #000;
	z-index: 1;
	align-items: center;
	position: relative;
	top: 0;
	left: 0;
	@media screen and (max-width:$width - 1px){
		height: $sp-height;
	}
	.concept{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		color: #FFF;
		text-shadow: 0 0 32px rgba(#000,.64);
		z-index: 2;
		@include flex();
		align-items: center;
		justify-content: center;
		text-align: center;
		font-weight: 900;
		background: rgba(#000,.48);
		dl{
			@media screen and (max-width:$width - 1px){
				width: 80%;	
			}
			dt{
				font-size: 35px;
				text-shadow: 0 0 32px rgba(#000,.16);
				margin: 0 0 4px;
				@media screen and (max-width:$width - 1px){
					font-size: 20px;
				}
			}
			dd{
				font-size: 17px;
				@media screen and (max-width:$width - 1px){
					font-size: 14px;	
				}
			}
		}
	}
	.swiper-slide{
		height: $height;
		@media screen and (max-width:$width - 1px){
			height: $sp-height;
		}
	}
	.swipe-bg{
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 100%;
	}
}