.site-newbuild{
	.threes{
		@include flex();
		margin: 0 0 48px;
		@media screen and (max-width:$width - 1px){
			margin: 0 0 24px;
		}
		li{
			width: 30%;
			figure{
				img{
					width: 100%;
				}
				figcaption{
					color: $green;
					text-align: center;
					font-size: 16px;
					font-weight: 700;
					margin: 12px 0 0;
					@media screen and (max-width:$width - 1px){
						font-size: 13px;
					}
				}
			}
		}
	}
	.context{
		text-align: center;
		color: $blue;
		font-weight: 700;
		font-size: 20px;
		@media screen and (max-width:$width - 1px){
			font-size: 13px;
		}
	}
	
	.listable{
		.seclist{
			li{
				margin: 0 0 56px;
				&:last-child{
					margin: 0;
				}
			}
			h4{
				background: $purple;
				color: #FFF;
				padding: 6px 16px;
				border-radius: 6px;
				font-size: 20px;
				font-weight: 700;
				margin: 0 0 24px;
				@media screen and (max-width:$width - 1px){
					font-size: 17px;
				}
			}
			figure{
				width: 40%;
                border: #FFF 8px solid;
				box-shadow: 8px 8px 0 rgba(#000,.12);
				float: right;
				@media screen and (max-width:$width - 1px){
					width: 100%;
					margin: 0 0 24px;
					float: none;
				}
				img{
					width: 100%;
				}
			}
			div.data{
				width: 60%;
				padding: 0 48px 0 0;
				float: left;
				@media screen and (max-width:$width - 1px){
					float: none;
					padding: 0;
					width: auto;
				}
				> dl{
					position: relative;
					margin: 0 0 32px;
					&:last-of-type{
						margin: 0;
					}
					&:before{
						content: '';
						position: absolute;
						top: 16px;
						left: 0;
						width: 100%;
						height: 1px;
						border-bottom: rgba(#000,.12) 1px dashed;
						@media screen and (max-width:$width - 1px){
							top: 14px;
						}
					}
					dt{
						color: $green;
						background: $glay;
						padding: 0 16px 0 0;
						display: inline-block;
						font-size: 18px;
						font-weight: 700;
						position: relative;
						z-index: 2;
						@media screen and (max-width:$width - 1px){
							font-size: 16px;
						}
					}
					dd{
						margin: 4px 0 0;
						text-align: justify;
					}
				}
			}
		}
	}
	
	.works{
		.list{
			@include flex();
			@media screen and (max-width:$width - 1px){
				display: block;
			}
			li{
				list-style: none;
				width: 31%;
				background: $glay;
				box-shadow: 8px 8px 0 rgba(#000,.12);
				@media screen and (max-width:$width - 1px){
					width: 100%;
					margin: 0 0 24px;
					&:last-child{
						margin: 0;
					}
				}
				img{
					width: 100%;
				}
				dl{
					padding: 24px 16px 16px;
					dt{
						color: $blue;
						line-height: 1;
						font-size: 18px;
						text-align: center;
						font-weight: 700;
						border-bottom: $purple 2px solid;
						padding: 0 0 16px;
						margin: 0 0 16px;
					}
					dd{
						margin: 0 0 8px;
						&:last-of-type{
							margin: 0;
						}
						&:before{
							content: attr(data-text) "：";
						}
						&.total{
							font-weight: 700;
							color: $green;
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}