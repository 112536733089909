.site-contact{
	i.hiss{
		color: #D22;
	}
	.text{
		text-align: center;
		margin: 0 0 48px;
		@media screen and (max-width:$width - 1px){
			text-align: justify;
			margin: 0 0 32px;
			br{
				display: none;
			}
		}
	}
	.form{
		dl{
			margin: 0 0 24px;
			dt{
				font-size: 15px;
				font-weight: 500;
				margin: 0 0 4px;
			}
			dd{
				input,
				textarea{
					border: $light-green 1px solid;
					padding: 12px;
					width: 100%;
					@include font();
					font-size: 15px;
					border-radius: 4px;
				}
				textarea{
					height: 100px;
				}
			}
		}
	}
	.send{
		input{
			width: 100%;
			border: none;
			background: $green;
			color: #FFF;
            @include font();
            font-size: 18px;
            border-radius: 4px;
			padding: 12px 0;
			font-weight: 700;
			cursor: pointer;
			&:hover{
				background: $blue;
			}
		}
	}
}