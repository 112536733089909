.site-index{
	.history{
		color: #FFF;
		text-align: center;
		dl{
			padding: 0 0 56px;
			@media screen and (max-width:$width - 1px){
				padding: 0 0 32px;
			}
			dt{
				font-size: 32px;
				font-weight: 700;
				line-height: 1.2;
				border-bottom: rgba(#FFF,.48) 1px solid;
				margin: 0 0 20px;
				padding: 0 0 20px;
				@media screen and (max-width:$width - 1px){
					font-size: 20px;
					margin: 0 0 16px;
					padding: 0 0 16px;
				}
				i{
					font-size: 50px;
					color: $light-green;
					display: block;
					margin: 0 0 24px;
					@media screen and (max-width:$width - 1px){
						font-size: 40px;
					}
				}
			}
			dd{
				font-size: 17px;
				@media screen and (max-width:$width - 1px){
					font-size: 13px;
				}
			}
		}
	}
	.history-svg{
		transform: rotate(180deg);
		background: $green;
		display: block;
		position: relative;
		&:after{
			content: '';
			width: 100%;
			height: 2px;
			background: $glay;
			position: absolute;
			left: 0;
			top: -1px;
		}
		.fill-white{
			fill: $glay;
		}
	}
	
	.works{
		.inner{
			padding: 0 24px;
			position: relative;
			@media screen and (max-width:$width - 1px){
				padding: 0;
			}
		}
		.swiper-wrapper{
			transition-timing-function:cubic-bezier(0.86, 0, 0.07, 1);
		}
		.swiper-slide{
			width: 200px;
			@media screen and (max-width:$width - 1px){
				width: 100%;
				padding: 0 40px;
			}
		}
		.swiper-button-cu-next,
		.swiper-button-cu-prev{
			position: absolute;
			top: calc(50% - 24px);
			transform: translateY(-50%);
			z-index: 100;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			border: rgba(#000,.24) 2px solid;
			cursor: pointer;
			@include flex();
			align-items: center;
			justify-content: center;
			&:hover{
				opacity: .64;
			}
			i{
				font-size: 14px;
				color: rgba(#000,.24);
			}
		}
		.swiper-button-cu-next{
			right: -20px;
			@media screen and (max-width:$width - 1px){
				right: 0;
			}
			i{
				transform: translateX(1px);
			}
		}
		.swiper-button-cu-prev{
			left: -20px;
			@media screen and (max-width:$width - 1px){
				left: 0;
			}
			i{
				transform: translateX(-1px);
			}
		}
		.swiper-pagination{
			position: relative;
			margin: 24px 0 0;
			.swiper-pagination-bullet{
				margin: 0 6px;
				border: #aaa 1px solid;
				background: #aaa;
				width: 11px;
				height: 11px;
				opacity: 1;
			}
			.swiper-pagination-bullet-active{
				background: none;
			}
		}
		.swipe-bg{
			height: 200px;
			background-size: cover;
			border-radius: 6px;
			position: relative;
			overflow: hidden;
			@include flex();
			align-items: center;
			justify-content: center;
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				background: #000;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				@include transition(200ms);
			}
			&:hover{
				&:before{
					opacity: .64;
				}
				span{
					opacity: 1;
				}
			}
			span{
				position: relative;
				display:inline-block;
				text-align: center;
				color: #FFF;
				opacity: 0;
				@include transition(200ms);
			}
		}
	}
	
	.service{
		.list{
			list-style: none;
			@include flex();
			li{
				width: 188px;
				@media screen and (max-width:$width - 1px){
					width: 160px;
					margin: 0 0 24px;
				}
				figure{
					text-align: center;
					img{
						display: block;
						width: 100%;
						height: 188px;
						border-radius: 50%;
						box-shadow: 4px 4px 0 rgba(#000,.12);
						@media screen and (max-width:$width - 1px){
							height: 160px;	
						}
					}
					figcaption{
						margin: 24px 0 0;
						font-weight: 700;
						font-size: 16px;
						line-height: 1.5;
						color: $green;
						@media screen and (max-width:$width - 1px){
							font-size: 13px;	
						}
					}
				}
			}
		}
        .txt{
			margin: 32px 0 0;
			@media screen and (max-width:$width - 1px){
				margin: 16px 0 0;	
			}
            dt{
                color: $blue;
                font-size: 28px;
                text-align: center;
                font-weight: 700;
				@media screen and (max-width:$width - 1px){
					font-size: 14px;	
				}
            }
            .btnarea{
                margin: 32px 0 0;
            }
        }

	}
	
	.blog{
		ol{
			@include flex();
			@media screen and (max-width:$width - 1px){
				display: block;	
			}
			li{
				list-style: none;
				width: 280px;
				background: #FFF;
				border-radius: 6px;
				overflow: hidden;
				box-shadow: 4px 4px 0 rgba(#000,.12);
				@media screen and (max-width:$width - 1px){
					width: auto;
					margin: 0 0 15px;
					&:last-child{
						margin: 0;
					}
				}
				.bg{
					display: block;
					height: 160px;
					background-image: url("../../../images/common/noimg.png");
					background-size: cover;
					@include transition(200ms);
					&:hover{
						opacity: .88;
					}
				}
				dl{
					padding: 16px;
					dt{
						font-size: 16px;
						font-weight: 700;
						color: $green;
						margin: 0 0 4px;
					}
					dd{
						color: $blue;
						&.btnarea{
							margin: 12px 0 0;
							a{
								padding: 8px 0;
								min-width: 100%;
								max-width: 100%;
							}
						}
					}
				}
				.date{
					padding: 16px;
					border-top: $border 1px solid;
					color: rgba($blue,.48);
					i{
						margin: 0 12px 0 0;
						display: inline-block;
					}
				}
			}
		}
	}
	
	.news{
		.display{
			@include flex();
			@media screen and (max-width:$width - 1px){
				display: block;
			}
			h3{
				width: 15%;
				@media screen and (max-width:$width - 1px){
					width: auto;	
				}
			}
			ol{
				width: 65%;
				@media screen and (max-width:$width - 1px){
					width: auto;	
				}
			}
			a.more{
				width: 12%;
				@media screen and (max-width:$width - 1px){
					width: auto;	
				}
			}
		}
		$height: 150px;
		h3{
			color: #FFF;
			font-weight: 700;
			font-size: 16px;
			line-height: 1;
			position: relative;
			@media screen and (max-width:$width - 1px){
				text-align: center;
				font-size: 14px;
				margin: 0 0 32px;
			}
			&:before{
				content:attr(date-eng);
				display: block;
				font-size: 40px;
				margin: 0 0 24px;
				@media screen and (max-width:$width - 1px){
					font-size: 24px;	
				}
			}
			&:after{
				content: '';
				width: 50%;
				background: $green;
				height: 4px;
				position: absolute;
				top: 48px;
				left: 0;
				@media screen and (max-width:$width - 1px){
					left: 50%;
					transform: translateX(-50%);
					width: 72px;
					top: 32px;
				}
			}
		}
		ol{
			height: $height;
			overflow: auto;
			padding: 0 0 0 40px;
			border-left: rgba(#FFF,.12) 1px solid;
			@media screen and (max-width:$width - 1px){
				padding: 15px;
				border: rgba(#FFF,.12) 1px solid;
			}
			li{
				list-style: none;
				margin: 0 0 16px;
				padding: 0 0 16px;
				border-bottom: rgba(#FFF,.12) 1px solid;
				&:last-child{
					margin: 0;
					padding: 0;
					border: none;
				}
				a{
					color: #FFF;
					&:hover{
						text-decoration: underline;
					}
					time{
						background: $green;
						color: #FFF;
						padding: 2px 16px;
						display: inline-block;
						margin: 0 12px 0 0;
						font-size: 12px;
					}
				}
			}
		}
		a.more{
			background: $green;
			color: #FFF;
			@include flex();
			align-items: center;
			justify-content: center;
			text-align: center;
			@include transition(200ms);
			@media screen and (max-width:$width - 1px){
				display: block;
				margin: 24px 0 0;
				padding: 12px 15px;
				font-size: 16px;
				border-radius: 6px;
			}
			&:hover{
				opacity: .64;
			}
			i{
				font-size: 24px;
				display: block;
				margin: 0 0 4px;
				@media screen and (max-width:$width - 1px){
					font-size: 15px;
					display: inline-block;
					margin: 0 8px 0 0;
				}
			}
		}
	}
}