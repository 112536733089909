.site-recruit{
	.recl-kussion{
		background: $blue;
		position: relative;
		.display{
			&:before{
				content: '';
				position: absolute;
				top: 24px;
				left: -10%;
				width: 42%;
				height: calc(100% - 24px);
				background: url("../../../images/recruit/rec_img.png");
				background-size: cover;
				@media screen and (max-width:$width - 1px){
					display: none;
				}
			}	
		}
		dl{
			width: 50%;
			margin-left: 50%;
			padding: 90px 0;
			color: #FFF;
			@media screen and (max-width:$width - 1px){
				width: auto;
				margin: 0;
				padding: 32px 15px;
				text-align: center;
			}
			dt{
				font-size: 20px;
				font-weight: 700;
				@media screen and (max-width:$width - 1px){
					font-size: 14px;
				}
			}
			dd{
				margin: 12px 0 0;
				font-size: 16px;
				font-weight: 700;
				a{
					color: $yellow;
					font-size: 48px;
					font-weight: 700;
					@media screen and (max-width:$width - 1px){
						font-size: 28px;
					}
					i{
						margin: 0 16px 0 0;
						display: inline-block;
						transform: rotate(90deg);
					}
				}
			}
		}
	}
}