@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#404040;
$link:#19a591;
$border:#e5e5e5;

$blue: #005481;
$green: #19a591;
$light-blue: #37a0c8;
$light-green: #b2d6b5;
$purple: #5a6d8f;
$yellow:#eadf3f;
$glay:#eef1f7;


/* SITE SETTING
------------------------------------------------------------*/
$width:920px;
$max:1140px;

$pc-head:100px;
$sp-head:72px;


@media screen and (min-width:$width){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
	
	.display{
		&.wide{
			width: auto;
			min-width: $width;
			max-width: $max;
		}
	}
}

@media screen and (max-width:$width - 1px){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/firstview";
@import "page/index";
@import "page/newbuild";
@import "page/reform";
@import "page/flow";
@import "page/company";
@import "page/recruit";
@import "page/contact";
@import "page/sitemap";
@import "page/privacy";
@import "page/works";
@import "page/archive";

#site-wrapper,
#site-footer{
    position: relative;
    top: $pc-head;
    @media screen and (max-width:768px){
        top: $sp-head;
    }
}

#container{
	width: 70%;
	padding: 0 40px 0 0;
	float: left;
	@media screen and (max-width:$width - 1px){
		width: auto;
		padding: 0;
		float: none;
	}
}

#sidebar{
	width: 30%;
	float: right;
	@media screen and (max-width:$width - 1px){
		width: auto;
		float: none;
		margin: 32px 0 0;
	}
	h3{
        background: $purple;
		color: #FFF;
		font-size: 16px;
        padding: 10px 16px;
		margin: 0 0 24px;
		i{
			display: inline-block;
			margin: 0 12px 0 0;
		}
	}
    .btnarea{
        a{
            min-width: 100%;
        }
    }
}

#pager{
	margin: 32px 0 0;
	text-align: center;
	a,span{
		display: inline-block;
		width: 28px;
		height: 28px;
		line-height: 28px;
		border: $purple 1px solid;
		background: $purple;
		color: #FFF;
		text-align: center;
		margin: 0 6px;
	}
	span{
		background: #FFF;
		color: $normal;
	}
	a:hover{
		border: $blue 1px solid;
		background: $blue;
	}
}

.kussion{
    background: url("../../images/common/contact_bg.jpg") no-repeat center;
    background-size: cover;
    padding: 96px 0;
    position: relative;
	color: #FFF;
	text-align: center;
	@media screen and (max-width:$width - 1px){
		padding: 32px 15px;
	}
    &:before{
        content: '';
        background: $blue;
		width: 100%;
		height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: .8;
    }
	dl{
		position: relative;
		z-index: 2;
		dt{
			font-size: 22px;
			font-weight: 700;
			@media screen and (max-width:$width - 1px){
				font-size: 14px;
			}
		}
	}
}

.site-module{
	&.wrap{
		padding: 96px 0;
		@media screen and (max-width:$width - 1px){
			padding: 40px 15px;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	&.bg-glay{
		background: $glay;
	}
	&.bg-green{
		background: $green;
	}
	&.bg-blue{
		background: $blue;
	}
	&.bg-purple{
		background: $purple;
	}
	
	h2.large,
	h3.large{
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		color: $blue;
		line-height: 1;
		margin: 0 0 32px;
		@media screen and (max-width:$width - 1px){
			margin: 0 0 24px;
		}
		&.white{
			color: #FFF;
		}
		&.lite{
			&:after{
				background: $light-green;
			}
		}
		&:before{
			content: attr(data-eng);
			display: block;
			font-size: 36px;
			margin: 0 0 32px;
			@media screen and (max-width:$width - 1px){
				font-size: 26px;
				margin: 0 0 24px;
			}
		}
		&:after{
			content: '';
			width: 72px;
			height: 4px;
			background: $green;
			position: absolute;
			top: 48px;
			left: 50%;
			transform: translateX(-50%);
			@media screen and (max-width:$width - 1px){
				top: 34px;	
			}
		}
	}
	h3.mes{
		text-align: center;
		font-size: 28px;
		font-weight: 700;
		margin: 0 0 32px;
		color: $blue;
		@media screen and (max-width:$width - 1px){
			font-size: 18px;
			margin: 0 0 24px;
		}
		&.no-bottom{
			margin-bottom: 0;
		}
	}
	.btnarea{
		text-align: center;
		margin: 40px 0 0;
		@media screen and (max-width:$width - 1px){
			margin: 24px 0 0;	
		}
		a{
			display: inline-block;
			min-width: 320px;
			padding: 12px 0;
			color: #FFF;
			font-size: 16px;
			font-weight: 700;
			background: $green;
			border-radius: 4px;
			margin: 0 8px;
			position: relative;
			@media screen and (max-width:$width - 1px){
				display: block;
				min-width: auto;
				width: 100%;
				font-size: 15px;
				margin: 0;
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&:hover{
				background: lighten($green,8%);
			}
			&.one{
				margin: 0;
			}
			i{
				position: absolute;
				top: 50%;
				right: 16px;
				transform: translateY(-50%);
				font-size: 13px;
			}
		}
	}
	.animate-border{
		display: inline-block;
		position: relative;
		@media screen and (max-width:$width - 1px){
			border-bottom: $blue 2px solid;
		}
		&:after{
			content: '';
			width: 100%;
			height: 9px;
			border-radius: 999px;
			background: url("../../images/common/snake_border.png") repeat-x 0 0;
			background-size: 24px;
			position: absolute;
			left: 0;
			bottom: 0;
			@media screen and (max-width:$width - 1px){
				display: none;
			}
		}
	}
	.table-style{
		width: 100%;
		border-collapse: collapse;
		tbody{
			tr{
				th,td{
					padding: 16px 8px;
					border-bottom: $light-green 1px dotted;
					text-align: left;
					vertical-align: middle;
					font-weight: 500;
				}
				th{
					width: 20%;
					@media screen and (max-width:$width - 1px){
						width: 30%;
					}
				}
			}
		}
	}
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
	opacity: 0;
}