.site-bread{
	height: 210px;
	background-size: cover;
	background-position: center;
	position: relative;
	@include flex();
	align-items: center;
	justify-content: center;
	color: #FFF;
	@media screen and (max-width:$width - 1px){
		height: 160px;
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		background: #000;
		opacity: .32;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	> * {
		z-index: 2;
		position: relative;
	}
	a{
		color: #FFF;
	}
	h2{
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		line-height: 1;
		@media screen and (max-width:$width - 1px){
			font-size: 14px;
			transform: translateY(-12px);
		}
		&:before{
			content: attr(data-eng);
			font-size: 36px;
			display: block;
			margin: 0 0 10px;
			@media screen and (max-width:$width - 1px){
				font-size: 24px;	
			}
		}
	}
	nav{
		position: absolute;
		left: 0;
		bottom: 12px;
		width: 100%;
		ol{
			@media screen and (max-width:$width - 1px){
				padding: 0 15px;
			}
			li{
				list-style: none;
				float: left;
				font-size: 12px;
				@media screen and (max-width:$width - 1px){
					font-size: 10px;
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
				}
				&:after{
					content: '\f105';
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					display: inline-block;
					margin: 0 12px;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
	}
}