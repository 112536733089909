.site-sitemap{
	.list{
		li{
			@media screen and (min-width:$width){
				@include clum(3,32%,2%);
			}
			@media screen and (max-width:$width - 1px){
				list-style: none;
				margin: 0 0 15px;
				&:last-child{
					margin: 0;
				}
			}
			a{
				display: block;
				padding: 12px 0;
				color: #FFF;
				font-size: 16px;
				font-weight: 700;
				background: $green;
				border-radius: 4px;
				margin: 0 8px;
				position: relative;
				text-align: center;
				@media screen and (max-width:$width - 1px){
					margin: 0;
					padding: 12px 15px;
					text-align: left;
				}
				&:hover{
					background: lighten($green,8%);
				}
				i{
					position: absolute;
					top: 50%;
					right: 16px;
					transform: translateY(-50%);
					font-size: 13px;

				}
			}
		}
	}
}