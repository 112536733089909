@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 920px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #404040;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: 'Roboto',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #19a591; }
    a:hover {
      text-decoration: none;
      color: #20d1b8; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, #site-header .container__emet__gnavi > ul:after, #site-header .container__contact ul:after, .site-works .arrows ul li a:after, .site-archive .detail-box .arrows ul li a:after, .site-archive .minilist li a:after, .site-archive .social-link:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 920px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; }
  .display.wide {
    width: auto;
    min-width: 920px;
    max-width: 1140px; } }

@media screen and (max-width: 919px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #404040;
    background: #FFF;
    font-size: 12px;
    line-height: 1.8;
    font-family: 'Roboto',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #19a591; }
    a:hover {
      text-decoration: none;
      color: #20d1b8; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after, #site-header .container__emet__gnavi > ul:after, #site-header .container__contact ul:after, .site-works .arrows ul li a:after, .site-archive .detail-box .arrows ul li a:after, .site-archive .minilist li a:after, .site-archive .social-link:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  background: #FFF;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 80ms 0ms ease-in-out;
  -moz-transition: all 80ms 0ms ease-in-out;
  -ms-transition: all 80ms 0ms ease-in-out;
  -o-transition: all 80ms 0ms ease-in-out;
  transition: all 80ms 0ms ease-in-out; }
  @media screen and (max-width: 919px) {
    #site-header {
      height: 72px; } }
  #site-header.active {
    top: -20px; }
  #site-header h1 {
    background: #005481;
    color: #FFF;
    font-size: 10px;
    line-height: 20px;
    height: 20px;
    padding: 0 20px;
    font-weight: 700; }
    @media screen and (max-width: 919px) {
      #site-header h1 {
        padding: 0 15px; } }
  #site-header .container {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 919px) {
      #site-header .container {
        height: auto;
        display: block; } }
    #site-header .container__emet {
      padding: 0 0 0 20px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 919px) {
        #site-header .container__emet {
          padding: 0 15px;
          display: block; } }
      #site-header .container__emet__logo {
        width: 180px;
        margin: 0 4vw 0 0; }
        @media screen and (max-width: 1080px) {
          #site-header .container__emet__logo {
            width: 150px; } }
        @media screen and (max-width: 919px) {
          #site-header .container__emet__logo {
            width: 120px;
            margin: 13px 0 0;
            float: left; } }
        #site-header .container__emet__logo img {
          width: 100%; }
      #site-header .container__emet__gnavi {
        font-weight: 700;
        font-size: 15px; }
        @media screen and (max-width: 919px) {
          #site-header .container__emet__gnavi {
            width: 32px;
            height: 32px;
            background: url("../../../images/common/responsive_nav_2x.png") no-repeat 0 0;
            background-size: 32px;
            float: right;
            margin: 9px 0 0;
            cursor: pointer; }
            #site-header .container__emet__gnavi.is-active {
              background: url("../../../images/common/responsive_nav_2x.png") no-repeat 0 -32px;
              background-size: 32px; }
              #site-header .container__emet__gnavi.is-active > ul {
                display: block; } }
        @media screen and (max-width: 919px) {
          #site-header .container__emet__gnavi > ul {
            position: absolute;
            top: 52px;
            left: 0;
            width: 100%;
            display: none;
            box-shadow: 0 48px 48px rgba(0, 0, 0, 0.24); } }
        #site-header .container__emet__gnavi > ul > li {
          list-style: none;
          float: left;
          margin: 0 40px 0 0; }
          @media screen and (max-width: 1080px) {
            #site-header .container__emet__gnavi > ul > li {
              margin: 0 24px 0 0; } }
          @media screen and (max-width: 919px) {
            #site-header .container__emet__gnavi > ul > li {
              float: none;
              margin: 0;
              border-top: #e5e5e5 1px solid; } }
          #site-header .container__emet__gnavi > ul > li:last-child {
            margin: 0; }
          #site-header .container__emet__gnavi > ul > li > a,
          #site-header .container__emet__gnavi > ul > li dt {
            color: #005481;
            height: 80px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer; }
            @media screen and (max-width: 919px) {
              #site-header .container__emet__gnavi > ul > li > a,
              #site-header .container__emet__gnavi > ul > li dt {
                height: auto;
                display: block;
                padding: 12px 15px;
                font-size: 15px;
                background: #FFF; }
                #site-header .container__emet__gnavi > ul > li > a:after,
                #site-header .container__emet__gnavi > ul > li dt:after {
                  content: '';
                  display: block;
                  clear: both; } }
            #site-header .container__emet__gnavi > ul > li > a i,
            #site-header .container__emet__gnavi > ul > li dt i {
              display: inline-block;
              margin: 0 0 0 8px;
              font-size: .8rem; }
              @media screen and (max-width: 919px) {
                #site-header .container__emet__gnavi > ul > li > a i,
                #site-header .container__emet__gnavi > ul > li dt i {
                  float: right;
                  margin: 8px 0 0; } }
            @media screen and (min-width: 920px) {
              #site-header .container__emet__gnavi > ul > li > a:after,
              #site-header .container__emet__gnavi > ul > li dt:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                z-index: 2;
                background: #19a591;
                width: 0;
                height: 3px;
                -webkit-transition: all 80ms 0ms ease-in-out;
                -moz-transition: all 80ms 0ms ease-in-out;
                -ms-transition: all 80ms 0ms ease-in-out;
                -o-transition: all 80ms 0ms ease-in-out;
                transition: all 80ms 0ms ease-in-out; }
              #site-header .container__emet__gnavi > ul > li > a:hover:after,
              #site-header .container__emet__gnavi > ul > li dt:hover:after {
                left: 0;
                width: 100%; } }
          #site-header .container__emet__gnavi > ul > li > dl {
            position: relative; }
            @media screen and (min-width: 920px) {
              #site-header .container__emet__gnavi > ul > li > dl:hover dd {
                display: block; } }
            #site-header .container__emet__gnavi > ul > li > dl dd {
              display: none;
              position: absolute;
              top: 80px;
              left: 0;
              font-size: 12px; }
              @media screen and (max-width: 919px) {
                #site-header .container__emet__gnavi > ul > li > dl dd {
                  position: relative;
                  top: 0;
                  left: 0;
                  font-size: 15px; }
                  #site-header .container__emet__gnavi > ul > li > dl dd.is-active {
                    display: block; } }
              #site-header .container__emet__gnavi > ul > li > dl dd li {
                border-bottom: #19a591 1px dashed;
                background: #b2d6b5; }
                #site-header .container__emet__gnavi > ul > li > dl dd li:last-child {
                  border: none; }
                #site-header .container__emet__gnavi > ul > li > dl dd li a {
                  white-space: nowrap;
                  padding: 16px 24px;
                  display: block;
                  color: #005481;
                  -webkit-transition: all 80mns 0ms ease-in-out;
                  -moz-transition: all 80mns 0ms ease-in-out;
                  -ms-transition: all 80mns 0ms ease-in-out;
                  -o-transition: all 80mns 0ms ease-in-out;
                  transition: all 80mns 0ms ease-in-out; }
                  @media screen and (max-width: 919px) {
                    #site-header .container__emet__gnavi > ul > li > dl dd li a {
                      padding: 12px 15px; } }
                  #site-header .container__emet__gnavi > ul > li > dl dd li a:hover {
                    background: #19a591;
                    color: #FFF; }
    @media screen and (max-width: 919px) {
      #site-header .container__contact {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%; } }
    #site-header .container__contact ul li {
      list-style: none;
      float: left;
      margin: 0 24px 0 0; }
      @media screen and (max-width: 919px) {
        #site-header .container__contact ul li {
          width: 50%;
          margin: 0; } }
      #site-header .container__contact ul li:last-child {
        margin: 0; }
      #site-header .container__contact ul li.tel dl {
        font-weight: 700;
        line-height: 1;
        color: #005481;
        margin: 18px 0 0; }
        @media screen and (max-width: 1080px) {
          #site-header .container__contact ul li.tel dl {
            margin: 10px 0 0; } }
        @media screen and (max-width: 919px) {
          #site-header .container__contact ul li.tel dl {
            margin: 0; } }
        #site-header .container__contact ul li.tel dl dt {
          font-size: 10px;
          margin: 0 0 8px; }
          @media screen and (max-width: 1080px) {
            #site-header .container__contact ul li.tel dl dt {
              line-height: 1.4; } }
          #site-header .container__contact ul li.tel dl dt br {
            display: none; }
            @media screen and (max-width: 1080px) {
              #site-header .container__contact ul li.tel dl dt br {
                display: inline; } }
          @media screen and (max-width: 919px) {
            #site-header .container__contact ul li.tel dl dt {
              display: none; } }
        #site-header .container__contact ul li.tel dl dd {
          font-size: 28px; }
          @media screen and (max-width: 1080px) {
            #site-header .container__contact ul li.tel dl dd {
              font-size: 20px; } }
          @media screen and (max-width: 919px) {
            #site-header .container__contact ul li.tel dl dd {
              font-size: 13px; } }
          #site-header .container__contact ul li.tel dl dd i {
            margin: 0 8px 0 0;
            display: inline-block;
            transform: rotateY(180deg); }
          #site-header .container__contact ul li.tel dl dd a {
            color: #37a0c8; }
            @media screen and (max-width: 919px) {
              #site-header .container__contact ul li.tel dl dd a {
                background: #37a0c8;
                color: #FFF;
                display: block;
                text-align: center;
                padding: 15px;
                line-height: 1.5; }
                #site-header .container__contact ul li.tel dl dd a:hover {
                  color: #FFF; } }
            #site-header .container__contact ul li.tel dl dd a:hover {
              color: #19a591; }
      #site-header .container__contact ul li.contact a {
        height: 80px;
        padding: 8px;
        background: #19a591;
        color: #FFF;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 1.5;
        text-align: center; }
        #site-header .container__contact ul li.contact a .sp {
          display: none; }
          @media screen and (max-width: 919px) {
            #site-header .container__contact ul li.contact a .sp {
              display: inline-block;
              margin: 0 8px 0 0;
              font-size: 13px;
              width: auto; } }
        @media screen and (max-width: 919px) {
          #site-header .container__contact ul li.contact a {
            display: block;
            height: auto;
            padding: 15px;
            font-size: 13px;
            font-weight: 700; }
            #site-header .container__contact ul li.contact a br {
              display: none; } }
        #site-header .container__contact ul li.contact a:hover {
          background: #37a0c8; }
        #site-header .container__contact ul li.contact a i {
          display: block;
          width: 100%;
          font-size: 22px; }
          @media screen and (max-width: 919px) {
            #site-header .container__contact ul li.contact a i {
              display: none; } }

#site-footer #addMap {
  height: 300px;
  background: #eee;
  position: relative;
  filter: grayscale(100%); }
  @media screen and (max-width: 919px) {
    #site-footer #addMap {
      height: 160px; } }
  #site-footer #addMap iframe {
    vertical-align: bottom;
    height: 100%; }

#site-footer .pageup {
  display: block;
  padding: 12px 0;
  background: #19a591;
  color: #FFF;
  text-align: center; }
  #site-footer .pageup:hover {
    background: #1ec8b0; }

#site-footer .fnav {
  background: #5a6d8f;
  padding: 48px 0; }
  @media screen and (max-width: 919px) {
    #site-footer .fnav {
      padding: 24px 15px; } }
  #site-footer .fnav ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start; }
    #site-footer .fnav ul dt, #site-footer .fnav ul li.border {
      border-bottom: rgba(255, 255, 255, 0.24) 1px solid;
      margin: 0 0 6px;
      padding: 0 0 6px;
      font-weight: 700; }
    #site-footer .fnav ul li {
      list-style: none;
      width: 130px;
      color: #FFF;
      font-size: 11px; }
      @media screen and (max-width: 919px) {
        #site-footer .fnav ul li {
          width: 47%;
          font-size: 13px;
          margin: 0 0 24px; } }
      #site-footer .fnav ul li a:hover {
        opacity: .64; }
      #site-footer .fnav ul li i {
        font-size: 10px;
        display: inline-block;
        margin: 0 4px 0 0;
        opacity: .64; }
      #site-footer .fnav ul li a {
        color: #FFF; }

#site-footer .copy {
  background: #005481;
  color: #FFF;
  padding: 32px 0;
  text-align: center;
  display: block;
  font-size: 11px; }
  @media screen and (max-width: 919px) {
    #site-footer .copy {
      padding: 15px 15px 64px; } }

.site-bread {
  height: 210px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  color: #FFF; }
  @media screen and (max-width: 919px) {
    .site-bread {
      height: 160px; } }
  .site-bread:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .32;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .site-bread > * {
    z-index: 2;
    position: relative; }
  .site-bread a {
    color: #FFF; }
  .site-bread h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1; }
    @media screen and (max-width: 919px) {
      .site-bread h2 {
        font-size: 14px;
        transform: translateY(-12px); } }
    .site-bread h2:before {
      content: attr(data-eng);
      font-size: 36px;
      display: block;
      margin: 0 0 10px; }
      @media screen and (max-width: 919px) {
        .site-bread h2:before {
          font-size: 24px; } }
  .site-bread nav {
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100%; }
    @media screen and (max-width: 919px) {
      .site-bread nav ol {
        padding: 0 15px; } }
    .site-bread nav ol li {
      list-style: none;
      float: left;
      font-size: 12px; }
      @media screen and (max-width: 919px) {
        .site-bread nav ol li {
          font-size: 10px; } }
      .site-bread nav ol li i {
        display: inline-block;
        margin: 0 8px 0 0; }
      .site-bread nav ol li:after {
        content: '\f105';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        display: inline-block;
        margin: 0 12px; }
      .site-bread nav ol li:last-child:after {
        display: none; }

#firstview {
  width: 100%;
  height: 650px;
  background: #000;
  z-index: 1;
  align-items: center;
  position: relative;
  top: 0;
  left: 0; }
  @media screen and (max-width: 919px) {
    #firstview {
      height: calc(100vh - 72px - 48px); } }
  #firstview .concept {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #FFF;
    text-shadow: 0 0 32px rgba(0, 0, 0, 0.64);
    z-index: 2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    background: rgba(0, 0, 0, 0.48); }
    @media screen and (max-width: 919px) {
      #firstview .concept dl {
        width: 80%; } }
    #firstview .concept dl dt {
      font-size: 35px;
      text-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
      margin: 0 0 4px; }
      @media screen and (max-width: 919px) {
        #firstview .concept dl dt {
          font-size: 20px; } }
    #firstview .concept dl dd {
      font-size: 17px; }
      @media screen and (max-width: 919px) {
        #firstview .concept dl dd {
          font-size: 14px; } }
  #firstview .swiper-slide {
    height: 650px; }
    @media screen and (max-width: 919px) {
      #firstview .swiper-slide {
        height: calc(100vh - 72px - 48px); } }
  #firstview .swipe-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%; }

.site-index .history {
  color: #FFF;
  text-align: center; }
  .site-index .history dl {
    padding: 0 0 56px; }
    @media screen and (max-width: 919px) {
      .site-index .history dl {
        padding: 0 0 32px; } }
    .site-index .history dl dt {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
      border-bottom: rgba(255, 255, 255, 0.48) 1px solid;
      margin: 0 0 20px;
      padding: 0 0 20px; }
      @media screen and (max-width: 919px) {
        .site-index .history dl dt {
          font-size: 20px;
          margin: 0 0 16px;
          padding: 0 0 16px; } }
      .site-index .history dl dt i {
        font-size: 50px;
        color: #b2d6b5;
        display: block;
        margin: 0 0 24px; }
        @media screen and (max-width: 919px) {
          .site-index .history dl dt i {
            font-size: 40px; } }
    .site-index .history dl dd {
      font-size: 17px; }
      @media screen and (max-width: 919px) {
        .site-index .history dl dd {
          font-size: 13px; } }

.site-index .history-svg {
  transform: rotate(180deg);
  background: #19a591;
  display: block;
  position: relative; }
  .site-index .history-svg:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #eef1f7;
    position: absolute;
    left: 0;
    top: -1px; }
  .site-index .history-svg .fill-white {
    fill: #eef1f7; }

.site-index .works .inner {
  padding: 0 24px;
  position: relative; }
  @media screen and (max-width: 919px) {
    .site-index .works .inner {
      padding: 0; } }

.site-index .works .swiper-wrapper {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }

.site-index .works .swiper-slide {
  width: 200px; }
  @media screen and (max-width: 919px) {
    .site-index .works .swiper-slide {
      width: 100%;
      padding: 0 40px; } }

.site-index .works .swiper-button-cu-next,
.site-index .works .swiper-button-cu-prev {
  position: absolute;
  top: calc(50% - 24px);
  transform: translateY(-50%);
  z-index: 100;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: rgba(0, 0, 0, 0.24) 2px solid;
  cursor: pointer;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center; }
  .site-index .works .swiper-button-cu-next:hover,
  .site-index .works .swiper-button-cu-prev:hover {
    opacity: .64; }
  .site-index .works .swiper-button-cu-next i,
  .site-index .works .swiper-button-cu-prev i {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.24); }

.site-index .works .swiper-button-cu-next {
  right: -20px; }
  @media screen and (max-width: 919px) {
    .site-index .works .swiper-button-cu-next {
      right: 0; } }
  .site-index .works .swiper-button-cu-next i {
    transform: translateX(1px); }

.site-index .works .swiper-button-cu-prev {
  left: -20px; }
  @media screen and (max-width: 919px) {
    .site-index .works .swiper-button-cu-prev {
      left: 0; } }
  .site-index .works .swiper-button-cu-prev i {
    transform: translateX(-1px); }

.site-index .works .swiper-pagination {
  position: relative;
  margin: 24px 0 0; }
  .site-index .works .swiper-pagination .swiper-pagination-bullet {
    margin: 0 6px;
    border: #aaa 1px solid;
    background: #aaa;
    width: 11px;
    height: 11px;
    opacity: 1; }
  .site-index .works .swiper-pagination .swiper-pagination-bullet-active {
    background: none; }

.site-index .works .swipe-bg {
  height: 200px;
  background-size: cover;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center; }
  .site-index .works .swipe-bg:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
  .site-index .works .swipe-bg:hover:before {
    opacity: .64; }
  .site-index .works .swipe-bg:hover span {
    opacity: 1; }
  .site-index .works .swipe-bg span {
    position: relative;
    display: inline-block;
    text-align: center;
    color: #FFF;
    opacity: 0;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }

.site-index .service .list {
  list-style: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .site-index .service .list li {
    width: 188px; }
    @media screen and (max-width: 919px) {
      .site-index .service .list li {
        width: 160px;
        margin: 0 0 24px; } }
    .site-index .service .list li figure {
      text-align: center; }
      .site-index .service .list li figure img {
        display: block;
        width: 100%;
        height: 188px;
        border-radius: 50%;
        box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.12); }
        @media screen and (max-width: 919px) {
          .site-index .service .list li figure img {
            height: 160px; } }
      .site-index .service .list li figure figcaption {
        margin: 24px 0 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
        color: #19a591; }
        @media screen and (max-width: 919px) {
          .site-index .service .list li figure figcaption {
            font-size: 13px; } }

.site-index .service .txt {
  margin: 32px 0 0; }
  @media screen and (max-width: 919px) {
    .site-index .service .txt {
      margin: 16px 0 0; } }
  .site-index .service .txt dt {
    color: #005481;
    font-size: 28px;
    text-align: center;
    font-weight: 700; }
    @media screen and (max-width: 919px) {
      .site-index .service .txt dt {
        font-size: 14px; } }
  .site-index .service .txt .btnarea {
    margin: 32px 0 0; }

.site-index .blog ol {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 919px) {
    .site-index .blog ol {
      display: block; } }
  .site-index .blog ol li {
    list-style: none;
    width: 280px;
    background: #FFF;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.12); }
    @media screen and (max-width: 919px) {
      .site-index .blog ol li {
        width: auto;
        margin: 0 0 15px; }
        .site-index .blog ol li:last-child {
          margin: 0; } }
    .site-index .blog ol li .bg {
      display: block;
      height: 160px;
      background-image: url("../../../images/common/noimg.png");
      background-size: cover;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
      .site-index .blog ol li .bg:hover {
        opacity: .88; }
    .site-index .blog ol li dl {
      padding: 16px; }
      .site-index .blog ol li dl dt {
        font-size: 16px;
        font-weight: 700;
        color: #19a591;
        margin: 0 0 4px; }
      .site-index .blog ol li dl dd {
        color: #005481; }
        .site-index .blog ol li dl dd.btnarea {
          margin: 12px 0 0; }
          .site-index .blog ol li dl dd.btnarea a {
            padding: 8px 0;
            min-width: 100%;
            max-width: 100%; }
    .site-index .blog ol li .date {
      padding: 16px;
      border-top: #e5e5e5 1px solid;
      color: rgba(0, 84, 129, 0.48); }
      .site-index .blog ol li .date i {
        margin: 0 12px 0 0;
        display: inline-block; }

.site-index .news .display {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 919px) {
    .site-index .news .display {
      display: block; } }
  .site-index .news .display h3 {
    width: 15%; }
    @media screen and (max-width: 919px) {
      .site-index .news .display h3 {
        width: auto; } }
  .site-index .news .display ol {
    width: 65%; }
    @media screen and (max-width: 919px) {
      .site-index .news .display ol {
        width: auto; } }
  .site-index .news .display a.more {
    width: 12%; }
    @media screen and (max-width: 919px) {
      .site-index .news .display a.more {
        width: auto; } }

.site-index .news h3 {
  color: #FFF;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  position: relative; }
  @media screen and (max-width: 919px) {
    .site-index .news h3 {
      text-align: center;
      font-size: 14px;
      margin: 0 0 32px; } }
  .site-index .news h3:before {
    content: attr(date-eng);
    display: block;
    font-size: 40px;
    margin: 0 0 24px; }
    @media screen and (max-width: 919px) {
      .site-index .news h3:before {
        font-size: 24px; } }
  .site-index .news h3:after {
    content: '';
    width: 50%;
    background: #19a591;
    height: 4px;
    position: absolute;
    top: 48px;
    left: 0; }
    @media screen and (max-width: 919px) {
      .site-index .news h3:after {
        left: 50%;
        transform: translateX(-50%);
        width: 72px;
        top: 32px; } }

.site-index .news ol {
  height: 150px;
  overflow: auto;
  padding: 0 0 0 40px;
  border-left: rgba(255, 255, 255, 0.12) 1px solid; }
  @media screen and (max-width: 919px) {
    .site-index .news ol {
      padding: 15px;
      border: rgba(255, 255, 255, 0.12) 1px solid; } }
  .site-index .news ol li {
    list-style: none;
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
    .site-index .news ol li:last-child {
      margin: 0;
      padding: 0;
      border: none; }
    .site-index .news ol li a {
      color: #FFF; }
      .site-index .news ol li a:hover {
        text-decoration: underline; }
      .site-index .news ol li a time {
        background: #19a591;
        color: #FFF;
        padding: 2px 16px;
        display: inline-block;
        margin: 0 12px 0 0;
        font-size: 12px; }

.site-index .news a.more {
  background: #19a591;
  color: #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 200ms 0ms ease-in-out;
  -moz-transition: all 200ms 0ms ease-in-out;
  -ms-transition: all 200ms 0ms ease-in-out;
  -o-transition: all 200ms 0ms ease-in-out;
  transition: all 200ms 0ms ease-in-out; }
  @media screen and (max-width: 919px) {
    .site-index .news a.more {
      display: block;
      margin: 24px 0 0;
      padding: 12px 15px;
      font-size: 16px;
      border-radius: 6px; } }
  .site-index .news a.more:hover {
    opacity: .64; }
  .site-index .news a.more i {
    font-size: 24px;
    display: block;
    margin: 0 0 4px; }
    @media screen and (max-width: 919px) {
      .site-index .news a.more i {
        font-size: 15px;
        display: inline-block;
        margin: 0 8px 0 0; } }

.site-newbuild .threes, .site-reform .threes {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 48px; }
  @media screen and (max-width: 919px) {
    .site-newbuild .threes, .site-reform .threes {
      margin: 0 0 24px; } }
  .site-newbuild .threes li, .site-reform .threes li {
    width: 30%; }
    .site-newbuild .threes li figure img, .site-reform .threes li figure img {
      width: 100%; }
    .site-newbuild .threes li figure figcaption, .site-reform .threes li figure figcaption {
      color: #19a591;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin: 12px 0 0; }
      @media screen and (max-width: 919px) {
        .site-newbuild .threes li figure figcaption, .site-reform .threes li figure figcaption {
          font-size: 13px; } }

.site-newbuild .context, .site-reform .context {
  text-align: center;
  color: #005481;
  font-weight: 700;
  font-size: 20px; }
  @media screen and (max-width: 919px) {
    .site-newbuild .context, .site-reform .context {
      font-size: 13px; } }

.site-newbuild .listable .seclist li, .site-reform .listable .seclist li {
  margin: 0 0 56px; }
  .site-newbuild .listable .seclist li:last-child, .site-reform .listable .seclist li:last-child {
    margin: 0; }

.site-newbuild .listable .seclist h4, .site-reform .listable .seclist h4 {
  background: #5a6d8f;
  color: #FFF;
  padding: 6px 16px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 24px; }
  @media screen and (max-width: 919px) {
    .site-newbuild .listable .seclist h4, .site-reform .listable .seclist h4 {
      font-size: 17px; } }

.site-newbuild .listable .seclist figure, .site-reform .listable .seclist figure {
  width: 40%;
  border: #FFF 8px solid;
  box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.12);
  float: right; }
  @media screen and (max-width: 919px) {
    .site-newbuild .listable .seclist figure, .site-reform .listable .seclist figure {
      width: 100%;
      margin: 0 0 24px;
      float: none; } }
  .site-newbuild .listable .seclist figure img, .site-reform .listable .seclist figure img {
    width: 100%; }

.site-newbuild .listable .seclist div.data, .site-reform .listable .seclist div.data {
  width: 60%;
  padding: 0 48px 0 0;
  float: left; }
  @media screen and (max-width: 919px) {
    .site-newbuild .listable .seclist div.data, .site-reform .listable .seclist div.data {
      float: none;
      padding: 0;
      width: auto; } }
  .site-newbuild .listable .seclist div.data > dl, .site-reform .listable .seclist div.data > dl {
    position: relative;
    margin: 0 0 32px; }
    .site-newbuild .listable .seclist div.data > dl:last-of-type, .site-reform .listable .seclist div.data > dl:last-of-type {
      margin: 0; }
    .site-newbuild .listable .seclist div.data > dl:before, .site-reform .listable .seclist div.data > dl:before {
      content: '';
      position: absolute;
      top: 16px;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: rgba(0, 0, 0, 0.12) 1px dashed; }
      @media screen and (max-width: 919px) {
        .site-newbuild .listable .seclist div.data > dl:before, .site-reform .listable .seclist div.data > dl:before {
          top: 14px; } }
    .site-newbuild .listable .seclist div.data > dl dt, .site-reform .listable .seclist div.data > dl dt {
      color: #19a591;
      background: #eef1f7;
      padding: 0 16px 0 0;
      display: inline-block;
      font-size: 18px;
      font-weight: 700;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 919px) {
        .site-newbuild .listable .seclist div.data > dl dt, .site-reform .listable .seclist div.data > dl dt {
          font-size: 16px; } }
    .site-newbuild .listable .seclist div.data > dl dd, .site-reform .listable .seclist div.data > dl dd {
      margin: 4px 0 0;
      text-align: justify; }

.site-newbuild .works .list, .site-reform .works .list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 919px) {
    .site-newbuild .works .list, .site-reform .works .list {
      display: block; } }
  .site-newbuild .works .list li, .site-reform .works .list li {
    list-style: none;
    width: 31%;
    background: #eef1f7;
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.12); }
    @media screen and (max-width: 919px) {
      .site-newbuild .works .list li, .site-reform .works .list li {
        width: 100%;
        margin: 0 0 24px; }
        .site-newbuild .works .list li:last-child, .site-reform .works .list li:last-child {
          margin: 0; } }
    .site-newbuild .works .list li img, .site-reform .works .list li img {
      width: 100%; }
    .site-newbuild .works .list li dl, .site-reform .works .list li dl {
      padding: 24px 16px 16px; }
      .site-newbuild .works .list li dl dt, .site-reform .works .list li dl dt {
        color: #005481;
        line-height: 1;
        font-size: 18px;
        text-align: center;
        font-weight: 700;
        border-bottom: #5a6d8f 2px solid;
        padding: 0 0 16px;
        margin: 0 0 16px; }
      .site-newbuild .works .list li dl dd, .site-reform .works .list li dl dd {
        margin: 0 0 8px; }
        .site-newbuild .works .list li dl dd:last-of-type, .site-reform .works .list li dl dd:last-of-type {
          margin: 0; }
        .site-newbuild .works .list li dl dd:before, .site-reform .works .list li dl dd:before {
          content: attr(data-text) "："; }
        .site-newbuild .works .list li dl dd.total, .site-reform .works .list li dl dd.total {
          font-weight: 700;
          color: #19a591;
          font-size: 18px; }

.site-flow .flow li {
  list-style: none;
  background: #FFF;
  margin: 0 0 56px;
  padding: 24px;
  position: relative; }
  @media screen and (max-width: 919px) {
    .site-flow .flow li {
      margin: 0 0 32px;
      padding: 15px; } }
  .site-flow .flow li.green {
    background: #19a591;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 700; }
    .site-flow .flow li.green:before {
      border-color: #19a591 transparent transparent transparent; }
  .site-flow .flow li:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    bottom: -24px;
    border-style: solid;
    border-width: 32px 32px 0 32px;
    border-color: #FFF transparent transparent transparent;
    transform: translateX(-50%); }
  .site-flow .flow li:last-child {
    margin: 0; }
    .site-flow .flow li:last-child:before {
      display: none; }
  .site-flow .flow li figure {
    width: 30%;
    float: left; }
    @media screen and (max-width: 919px) {
      .site-flow .flow li figure {
        width: auto;
        float: none;
        margin: 0 0 16px; } }
    .site-flow .flow li figure img {
      width: 100%; }
  .site-flow .flow li dl {
    width: 70%;
    padding: 0 0 0 32px;
    float: right; }
    @media screen and (max-width: 919px) {
      .site-flow .flow li dl {
        width: auto;
        padding: 0; } }
    .site-flow .flow li dl dt {
      color: #FFF;
      background: #19a591;
      padding: 3px 16px;
      font-size: 17px;
      font-weight: 700;
      margin: 0 0 16px; }
      @media screen and (max-width: 919px) {
        .site-flow .flow li dl dt {
          font-size: 15px; } }

.site-company .txt {
  text-align: center;
  color: #FFF;
  font-size: 15px;
  line-height: 2.4; }
  @media screen and (max-width: 919px) {
    .site-company .txt {
      font-size: 13px;
      line-height: 1.8;
      text-align: left;
      padding: 0 15px; }
      .site-company .txt br {
        display: none; } }

.site-recruit .recl-kussion {
  background: #005481;
  position: relative; }
  .site-recruit .recl-kussion .display:before {
    content: '';
    position: absolute;
    top: 24px;
    left: -10%;
    width: 42%;
    height: calc(100% - 24px);
    background: url("../../../images/recruit/rec_img.png");
    background-size: cover; }
    @media screen and (max-width: 919px) {
      .site-recruit .recl-kussion .display:before {
        display: none; } }
  .site-recruit .recl-kussion dl {
    width: 50%;
    margin-left: 50%;
    padding: 90px 0;
    color: #FFF; }
    @media screen and (max-width: 919px) {
      .site-recruit .recl-kussion dl {
        width: auto;
        margin: 0;
        padding: 32px 15px;
        text-align: center; } }
    .site-recruit .recl-kussion dl dt {
      font-size: 20px;
      font-weight: 700; }
      @media screen and (max-width: 919px) {
        .site-recruit .recl-kussion dl dt {
          font-size: 14px; } }
    .site-recruit .recl-kussion dl dd {
      margin: 12px 0 0;
      font-size: 16px;
      font-weight: 700; }
      .site-recruit .recl-kussion dl dd a {
        color: #eadf3f;
        font-size: 48px;
        font-weight: 700; }
        @media screen and (max-width: 919px) {
          .site-recruit .recl-kussion dl dd a {
            font-size: 28px; } }
        .site-recruit .recl-kussion dl dd a i {
          margin: 0 16px 0 0;
          display: inline-block;
          transform: rotate(90deg); }

.site-contact i.hiss {
  color: #D22; }

.site-contact .text {
  text-align: center;
  margin: 0 0 48px; }
  @media screen and (max-width: 919px) {
    .site-contact .text {
      text-align: justify;
      margin: 0 0 32px; }
      .site-contact .text br {
        display: none; } }

.site-contact .form dl {
  margin: 0 0 24px; }
  .site-contact .form dl dt {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 4px; }
  .site-contact .form dl dd input,
  .site-contact .form dl dd textarea {
    border: #b2d6b5 1px solid;
    padding: 12px;
    width: 100%;
    font-family: 'Roboto',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    font-size: 15px;
    border-radius: 4px; }
  .site-contact .form dl dd textarea {
    height: 100px; }

.site-contact .send input {
  width: 100%;
  border: none;
  background: #19a591;
  color: #FFF;
  font-family: 'Roboto',"Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em;
  font-size: 18px;
  border-radius: 4px;
  padding: 12px 0;
  font-weight: 700;
  cursor: pointer; }
  .site-contact .send input:hover {
    background: #005481; }

@media screen and (min-width: 920px) {
  .site-sitemap .list li {
    list-style: none;
    width: 32%;
    float: left;
    margin: 0 2% 2% 0; }
    .site-sitemap .list li:nth-child(3n+1) {
      clear: both; }
    .site-sitemap .list li:nth-child(3n+3) {
      margin-right: 0; }
    .site-sitemap .list li:nth-last-child(-n+3) {
      margin-bottom: 0; } }

@media screen and (max-width: 919px) {
  .site-sitemap .list li {
    list-style: none;
    margin: 0 0 15px; }
    .site-sitemap .list li:last-child {
      margin: 0; } }

.site-sitemap .list li a {
  display: block;
  padding: 12px 0;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  background: #19a591;
  border-radius: 4px;
  margin: 0 8px;
  position: relative;
  text-align: center; }
  @media screen and (max-width: 919px) {
    .site-sitemap .list li a {
      margin: 0;
      padding: 12px 15px;
      text-align: left; } }
  .site-sitemap .list li a:hover {
    background: #1ec8b0; }
  .site-sitemap .list li a i {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 13px; }

.site-privacy .text {
  margin: 0 0 48px; }
  @media screen and (max-width: 919px) {
    .site-privacy .text {
      margin: 0 0 24px; } }

.site-privacy .margin {
  margin: 0 0 24px; }

.site-privacy ol li {
  list-style: inside decimal;
  margin: 0 0 3px; }
  .site-privacy ol li:last-child {
    margin: 0; }
  .site-privacy ol li > ol {
    padding: 12px 0 0 2em; }

.site-privacy .table-res-style {
  display: block; }
  .site-privacy .table-res-style tbody, .site-privacy .table-res-style tr, .site-privacy .table-res-style th, .site-privacy .table-res-style td {
    display: block; }
  .site-privacy .table-res-style tr {
    margin: 0 0 64px; }
    .site-privacy .table-res-style tr:last-child {
      margin: 0; }
    .site-privacy .table-res-style tr th, .site-privacy .table-res-style tr td {
      text-align: left; }
    .site-privacy .table-res-style tr th {
      background: #eef1f7;
      padding: 12px;
      color: #005481;
      font-weight: 700;
      margin: 0 0 12px;
      border-radius: 6px; }

.site-works .flexer {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start; }
  @media screen and (max-width: 919px) {
    .site-works .flexer {
      justify-content: space-between; } }
  .site-works .flexer li {
    list-style: none;
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.32); }
    @media screen and (min-width: 920px) {
      .site-works .flexer li {
        margin: 0 40px 40px 0; }
        .site-works .flexer li:nth-child(4n+4) {
          margin-right: 0; } }
    @media screen and (max-width: 919px) {
      .site-works .flexer li {
        margin: 0 0 24px; } }
    .site-works .flexer li .bg {
      width: 200px;
      height: 200px;
      display: block;
      background-position: center;
      background-size: cover;
      position: relative;
      -webkit-transition: all 200ms 0ms ease-in-out;
      -moz-transition: all 200ms 0ms ease-in-out;
      -ms-transition: all 200ms 0ms ease-in-out;
      -o-transition: all 200ms 0ms ease-in-out;
      transition: all 200ms 0ms ease-in-out; }
      @media screen and (max-width: 919px) {
        .site-works .flexer li .bg {
          width: 160px;
          height: 160px; } }
      .site-works .flexer li .bg:hover {
        opacity: .88; }
      .site-works .flexer li .bg i {
        background: rgba(0, 0, 0, 0.48);
        padding: 4px 8px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        font-size: 13px;
        color: #FFF;
        width: 100%; }

.site-works .detail #images {
  width: 48%;
  float: left;
  cursor: pointer; }
  @media screen and (max-width: 919px) {
    .site-works .detail #images {
      width: auto;
      float: none;
      margin: 0 0 24px; } }
  .site-works .detail #images .bg {
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 919px) {
      .site-works .detail #images .bg {
        height: 200px; } }

.site-works .detail .vs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 400ms 0ms ease-in-out;
  -moz-transition: all 400ms 0ms ease-in-out;
  -ms-transition: all 400ms 0ms ease-in-out;
  -o-transition: all 400ms 0ms ease-in-out;
  transition: all 400ms 0ms ease-in-out; }
  .site-works .detail .vs.is-active {
    background: rgba(0, 0, 0, 0.88); }
    .site-works .detail .vs.is-active * {
      opacity: 1; }
  .site-works .detail .vs * {
    opacity: 0; }
  .site-works .detail .vs img {
    max-width: 72%;
    max-height: 72%;
    width: auto;
    height: auto; }
    @media screen and (max-width: 919px) {
      .site-works .detail .vs img {
        max-width: 88%;
        max-height: 88%; } }
  .site-works .detail .vs .close {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer; }
    .site-works .detail .vs .close:hover:before, .site-works .detail .vs .close:hover:after {
      transform: rotate(0deg); }
    .site-works .detail .vs .close:before, .site-works .detail .vs .close:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #FFF;
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(45deg);
      -webkit-transition: all 120ms 0ms ease-in-out;
      -moz-transition: all 120ms 0ms ease-in-out;
      -ms-transition: all 120ms 0ms ease-in-out;
      -o-transition: all 120ms 0ms ease-in-out;
      transition: all 120ms 0ms ease-in-out; }
    .site-works .detail .vs .close:after {
      transform: rotate(-45deg); }

.site-works .detail .data {
  width: 48%;
  float: right; }
  @media screen and (max-width: 919px) {
    .site-works .detail .data {
      width: auto;
      float: none;
      margin: 24px 0 0; } }
  .site-works .detail .data dt {
    background: #5a6d8f;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    padding: 2px 0;
    margin: 0 0 16px; }
  .site-works .detail .data .meta {
    margin: 24px 0 0;
    padding: 24px 0 0;
    border-top: #b2d6b5 1px solid; }
    .site-works .detail .data .meta li {
      list-style: none;
      float: left;
      margin: 0 24px 0 0; }
      .site-works .detail .data .meta li:last-child {
        margin: 0; }
      .site-works .detail .data .meta li i {
        color: #19a591;
        display: inline-block;
        margin: 0 12px 0 0; }

.site-works .arrows {
  padding: 48px 0; }
  @media screen and (max-width: 919px) {
    .site-works .arrows {
      padding: 24px 15px; } }
  .site-works .arrows ul {
    position: relative;
    border-top: rgba(0, 0, 0, 0.12) 1px solid;
    border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
    padding: 16px 0; }
    .site-works .arrows ul:before {
      content: '';
      width: 1px;
      height: 60%;
      background: rgba(0, 0, 0, 0.12);
      position: absolute;
      top: 20%;
      left: 50%; }
    .site-works .arrows ul li {
      float: left;
      width: 50%; }
      .site-works .arrows ul li:nth-child(2) {
        float: right; }
        .site-works .arrows ul li:nth-child(2) i {
          float: right;
          margin: 0 0 0 16px; }
        .site-works .arrows ul li:nth-child(2) .txt {
          float: right;
          text-align: right; }
      .site-works .arrows ul li i {
        float: left;
        font-size: 32px;
        color: #394f7a;
        opacity: .8;
        margin: 0 16px 0 0; }
      .site-works .arrows ul li .txt {
        float: left;
        font-size: 11px;
        line-height: 1.5; }
        .site-works .arrows ul li .txt .title {
          color: #404040; }

.site-archive .archive-list li {
  background: #FFF;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
  margin: 0 0 32px; }
  .site-archive .archive-list li:last-child {
    margin: 0; }
  .site-archive .archive-list li .bg {
    width: 100%;
    height: 200px;
    display: block;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 919px) {
      .site-archive .archive-list li .bg {
        height: 140px; } }
    .site-archive .archive-list li .bg:hover {
      opacity: .88; }
  .site-archive .archive-list li dl {
    padding: 24px; }
    @media screen and (max-width: 919px) {
      .site-archive .archive-list li dl {
        padding: 15px; } }
    .site-archive .archive-list li dl dt {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 4px; }
    .site-archive .archive-list li dl dd.more {
      margin: 24px 0 0; }
      .site-archive .archive-list li dl dd.more i {
        display: inline-block;
        margin: 0 0 0 8px;
        opacity: .32; }
  .site-archive .archive-list li .date {
    border-top: #e5e5e5 1px solid;
    padding: 12px 24px; }

.site-archive .detail-box {
  background: #FFF;
  padding: 24px;
  border-radius: 3px;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.12);
  position: relative; }
  @media screen and (max-width: 919px) {
    .site-archive .detail-box {
      padding: 15px; } }
  .site-archive .detail-box h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 16px;
    padding: 0 100px 16px 0;
    border-bottom: rgba(90, 109, 143, 0.24) 1px solid; }
    @media screen and (max-width: 919px) {
      .site-archive .detail-box h3 {
        font-size: 18px;
        padding: 0 0 16px; } }
    .site-archive .detail-box h3 i {
      color: #19a591;
      display: inline-block;
      margin: 0 8px 0 0; }
  .site-archive .detail-box .date {
    position: absolute;
    top: 30px;
    right: 24px;
    font-size: 12px;
    color: #5a6d8f; }
    @media screen and (max-width: 919px) {
      .site-archive .detail-box .date {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 0 16px;
        display: block; } }
    .site-archive .detail-box .date i {
      display: inline-block;
      margin: 0 8px 0 0; }
  .site-archive .detail-box .detail-inner img {
    max-width: 100%; }
  .site-archive .detail-box .arrows {
    margin: 32px 0 0; }
    .site-archive .detail-box .arrows ul {
      position: relative;
      border-top: rgba(0, 0, 0, 0.12) 1px solid;
      border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
      padding: 16px 0; }
      .site-archive .detail-box .arrows ul:before {
        content: '';
        width: 1px;
        height: 60%;
        background: rgba(0, 0, 0, 0.12);
        position: absolute;
        top: 20%;
        left: 50%; }
      .site-archive .detail-box .arrows ul li {
        float: left;
        width: 50%; }
        .site-archive .detail-box .arrows ul li:nth-child(2) {
          float: right; }
          .site-archive .detail-box .arrows ul li:nth-child(2) i {
            float: right;
            margin: 0 0 0 16px; }
          .site-archive .detail-box .arrows ul li:nth-child(2) .txt {
            float: right;
            text-align: right; }
        .site-archive .detail-box .arrows ul li i {
          float: left;
          font-size: 32px;
          color: #394f7a;
          opacity: .16;
          margin: 0 16px 0 0; }
        .site-archive .detail-box .arrows ul li .txt {
          float: left;
          font-size: 11px;
          line-height: 1.5; }
          .site-archive .detail-box .arrows ul li .txt .title {
            color: #404040; }

.site-archive .minilist li {
  list-style: none;
  margin: 0 0 16px;
  padding: 0 0 16px;
  border-bottom: rgba(0, 0, 0, 0.16) 1px dotted; }
  .site-archive .minilist li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-archive .minilist li a {
    display: block; }
  .site-archive .minilist li .bg {
    width: 80px;
    height: 56px;
    background-position: center;
    background-size: cover;
    float: left;
    margin: 0 16px 0 0; }
  .site-archive .minilist li dl dt {
    color: #005481;
    margin: 4px 0 0; }
  .site-archive .minilist li dl dd {
    display: inline-block;
    background: #19a591;
    color: #FFF;
    padding: 2px 0;
    width: 96px;
    font-size: 12px;
    text-align: center; }

.site-archive .social-link {
  margin: 32px 0 0; }
  .site-archive .social-link li {
    list-style: none;
    float: left;
    margin: 0 8px 0 0; }
    .site-archive .social-link li:last-child {
      margin: 0; }
    .site-archive .social-link li a {
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      display: block;
      color: #FFF;
      font-size: 20px; }
      .site-archive .social-link li a.twitter {
        background: #52a7e7; }
      .site-archive .social-link li a.facebook {
        background: #41548f; }
      .site-archive .social-link li a:hover {
        opacity: .64; }

#site-wrapper,
#site-footer {
  position: relative;
  top: 100px; }
  @media screen and (max-width: 768px) {
    #site-wrapper,
    #site-footer {
      top: 72px; } }

#container {
  width: 70%;
  padding: 0 40px 0 0;
  float: left; }
  @media screen and (max-width: 919px) {
    #container {
      width: auto;
      padding: 0;
      float: none; } }

#sidebar {
  width: 30%;
  float: right; }
  @media screen and (max-width: 919px) {
    #sidebar {
      width: auto;
      float: none;
      margin: 32px 0 0; } }
  #sidebar h3 {
    background: #5a6d8f;
    color: #FFF;
    font-size: 16px;
    padding: 10px 16px;
    margin: 0 0 24px; }
    #sidebar h3 i {
      display: inline-block;
      margin: 0 12px 0 0; }
  #sidebar .btnarea a {
    min-width: 100%; }

#pager {
  margin: 32px 0 0;
  text-align: center; }
  #pager a, #pager span {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border: #5a6d8f 1px solid;
    background: #5a6d8f;
    color: #FFF;
    text-align: center;
    margin: 0 6px; }
  #pager span {
    background: #FFF;
    color: #404040; }
  #pager a:hover {
    border: #005481 1px solid;
    background: #005481; }

.kussion {
  background: url("../../images/common/contact_bg.jpg") no-repeat center;
  background-size: cover;
  padding: 96px 0;
  position: relative;
  color: #FFF;
  text-align: center; }
  @media screen and (max-width: 919px) {
    .kussion {
      padding: 32px 15px; } }
  .kussion:before {
    content: '';
    background: #005481;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .8; }
  .kussion dl {
    position: relative;
    z-index: 2; }
    .kussion dl dt {
      font-size: 22px;
      font-weight: 700; }
      @media screen and (max-width: 919px) {
        .kussion dl dt {
          font-size: 14px; } }

.site-module.wrap {
  padding: 96px 0; }
  @media screen and (max-width: 919px) {
    .site-module.wrap {
      padding: 40px 15px; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }

.site-module.bg-glay {
  background: #eef1f7; }

.site-module.bg-green {
  background: #19a591; }

.site-module.bg-blue {
  background: #005481; }

.site-module.bg-purple {
  background: #5a6d8f; }

.site-module h2.large,
.site-module h3.large {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #005481;
  line-height: 1;
  margin: 0 0 32px; }
  @media screen and (max-width: 919px) {
    .site-module h2.large,
    .site-module h3.large {
      margin: 0 0 24px; } }
  .site-module h2.large.white,
  .site-module h3.large.white {
    color: #FFF; }
  .site-module h2.large.lite:after,
  .site-module h3.large.lite:after {
    background: #b2d6b5; }
  .site-module h2.large:before,
  .site-module h3.large:before {
    content: attr(data-eng);
    display: block;
    font-size: 36px;
    margin: 0 0 32px; }
    @media screen and (max-width: 919px) {
      .site-module h2.large:before,
      .site-module h3.large:before {
        font-size: 26px;
        margin: 0 0 24px; } }
  .site-module h2.large:after,
  .site-module h3.large:after {
    content: '';
    width: 72px;
    height: 4px;
    background: #19a591;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (max-width: 919px) {
      .site-module h2.large:after,
      .site-module h3.large:after {
        top: 34px; } }

.site-module h3.mes {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 32px;
  color: #005481; }
  @media screen and (max-width: 919px) {
    .site-module h3.mes {
      font-size: 18px;
      margin: 0 0 24px; } }
  .site-module h3.mes.no-bottom {
    margin-bottom: 0; }

.site-module .btnarea {
  text-align: center;
  margin: 40px 0 0; }
  @media screen and (max-width: 919px) {
    .site-module .btnarea {
      margin: 24px 0 0; } }
  .site-module .btnarea a {
    display: inline-block;
    min-width: 320px;
    padding: 12px 0;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    background: #19a591;
    border-radius: 4px;
    margin: 0 8px;
    position: relative; }
    @media screen and (max-width: 919px) {
      .site-module .btnarea a {
        display: block;
        min-width: auto;
        width: 100%;
        font-size: 15px;
        margin: 0;
        margin-bottom: 15px; }
        .site-module .btnarea a:last-of-type {
          margin-bottom: 0; } }
    .site-module .btnarea a:hover {
      background: #1ec8b0; }
    .site-module .btnarea a.one {
      margin: 0; }
    .site-module .btnarea a i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 13px; }

.site-module .animate-border {
  display: inline-block;
  position: relative; }
  @media screen and (max-width: 919px) {
    .site-module .animate-border {
      border-bottom: #005481 2px solid; } }
  .site-module .animate-border:after {
    content: '';
    width: 100%;
    height: 9px;
    border-radius: 999px;
    background: url("../../images/common/snake_border.png") repeat-x 0 0;
    background-size: 24px;
    position: absolute;
    left: 0;
    bottom: 0; }
    @media screen and (max-width: 919px) {
      .site-module .animate-border:after {
        display: none; } }

.site-module .table-style {
  width: 100%;
  border-collapse: collapse; }
  .site-module .table-style tbody tr th, .site-module .table-style tbody tr td {
    padding: 16px 8px;
    border-bottom: #b2d6b5 1px dotted;
    text-align: left;
    vertical-align: middle;
    font-weight: 500; }
  .site-module .table-style tbody tr th {
    width: 20%; }
    @media screen and (max-width: 919px) {
      .site-module .table-style tbody tr th {
        width: 30%; } }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s; }

.fade-enter, .fade-leave-to {
  opacity: 0; }
