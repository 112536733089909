.site-company{
	.txt{
		text-align: center;
		color: #FFF;
		font-size: 15px;
		line-height: 2.4;
		@media screen and (max-width:$width - 1px){
			font-size: 13px;
			line-height: 1.8;
			text-align: left;
			padding: 0 15px;
			br{
				display: none;
			}
		}
	}
}