#site-footer{
	#addMap{
		height: 300px;
		background: #eee;
		position: relative;
		filter:grayscale(100%);
		@media screen and (max-width:$width - 1px){
			height: 160px;
		}
		iframe{
			vertical-align: bottom;
			height: 100%;
		}
	}
	.pageup{
		display: block;
		padding: 12px 0;
		background: $green;
		color: #FFF;
		text-align: center;
		&:hover{
			background: lighten($green,8%);
		}
	}
	.fnav{
		background: $purple;
		padding: 48px 0;
		@media screen and (max-width:$width - 1px){
			padding: 24px 15px;	
		}
		ul{
			@include flex();
			align-items: flex-start;
			dt,li.border{
				border-bottom: rgba(#FFF,.24) 1px solid;
				margin: 0 0 6px;
				padding: 0 0 6px;
				font-weight: 700;
			}
			li{
				list-style: none;
				width: 130px;
				color: #FFF;
				font-size: 11px;
				@media screen and (max-width:$width - 1px){
					width: 47%;
					font-size: 13px;
					margin: 0 0 24px;
				}
				a{
					&:hover{
						opacity: .64;
					}
				}
				i{
					font-size: 10px;
					display: inline-block;
					margin: 0 4px 0 0;
					opacity: .64;
				}
				a{
					color: #FFF;
				}
			}
		}
	}
	.copy{
		background: $blue;
		color: #FFF;
		padding: 32px 0;
		text-align: center;
		display: block;
		font-size: 11px;
		@media screen and (max-width:$width - 1px){
			padding: 15px 15px 64px;
		}
	}
}