#site-header{
	$h1: 20px;
	
	background: #FFF;
	width: 100%;
	height: $pc-head;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	@include transition(80ms);
	@media screen and (max-width:$width - 1px){
		height: $sp-head;	
	}
	&.active{
		top: -$h1;
	}
	h1{
		background: $blue;
		color: #FFF;
		font-size: 10px;
		line-height: $h1;
		height: $h1;
		padding: 0 20px;
		font-weight: 700;
		@media screen and (max-width:$width - 1px){
			padding: 0 15px;
		}
	}
	.container{
		position: absolute;
		top: $h1;
		left: 0;
		width: 100%;
		height: $pc-head - $h1;
		padding: 0;
		@include flex();
		align-items: center;
		@media screen and (max-width:$width - 1px){
			height: auto;
			display: block;
		}
		&__emet{
			padding: 0 0 0 20px;
			@include flex();
			align-items: center;
			@media screen and (max-width:$width - 1px){
				padding: 0 15px;
				display: block;
			}
			&__logo{
				width: 180px;
				margin: 0 4vw 0 0;
				@media screen and (max-width:1080px){
					width: 150px;
				}
				@media screen and (max-width:$width - 1px){
					width: 120px;
					margin: 13px 0 0;
					float: left;
				}
				img{
					width: 100%;
				}
			}
			&__gnavi{
				font-weight: 700;
				font-size: 15px;
				@media screen and (max-width:$width - 1px){
					width: 32px;
					height: 32px;
					background:url("../../../images/common/responsive_nav_2x.png") no-repeat 0 0;
					background-size: 32px;
					float: right;
					margin: 9px 0 0;
					cursor: pointer;
					&.is-active{
						background:url("../../../images/common/responsive_nav_2x.png") no-repeat 0 -32px;
						background-size: 32px;
						> ul{
							display: block;
						}
					}
				}
				> ul{
					@extend .clearfix;
					@media screen and (max-width:$width - 1px){
						position: absolute;
						top: $sp-head - $h1;;
						left: 0;
						width: 100%;
						display: none;
						box-shadow: 0 48px 48px rgba(#000,.24);
					}
					> li{
						list-style: none;
						float: left;
						margin: 0 40px 0 0;
						@media screen and (max-width:1080px){
							margin: 0 24px 0 0;	
						}
						@media screen and (max-width:$width - 1px){
							float: none;
							margin: 0;
							border-top: $border 1px solid;
						}
						&:last-child{
							margin: 0;
						}
						> a,
						dt{
							color: $blue;
							height: $pc-head - $h1;
							@include flex();
							align-items: center;
							position: relative;
							cursor: pointer;
							@media screen and (max-width:$width - 1px){
								height: auto;
								display: block;
								padding: 12px 15px;
								font-size: 15px;
								background: #FFF;
								&:after{
									content: '';
									display: block;
									clear: both;
								}
							}
							i{
								display: inline-block;
								margin: 0 0 0 8px;
								font-size: .8rem;
								@media screen and (max-width:$width - 1px){
									float: right;
									margin: 8px 0 0;
								}
							}
							@media screen and (min-width:$width){
								&:after{
									content: '';
									position: absolute;
									bottom: 0;
									left: 50%;
									z-index: 2;
									background: $green;
									width: 0;
									height: 3px;
									@include transition(80ms);
								}
								&:hover{
									&:after{
										left: 0;
										width: 100%;
									}
								}
							}	
						}
						> dl{
							position: relative;
							@media screen and (min-width:$width){
								&:hover{
									dd{
										display: block;
									}
								}
							}
							dd{
								display: none;
								position: absolute;
								top: $pc-head - $h1;
								left: 0;
								font-size: 12px;
								@media screen and (max-width:$width - 1px){
									position: relative;
									top: 0;
									left: 0;
									font-size: 15px;
									&.is-active{
										display: block;
									}
								}
								li{
									border-bottom: $green 1px dashed;
									background: $light-green;
									&:last-child{
										border: none;
									}
									a{
										white-space: nowrap;
										padding: 16px 24px;
										display: block;
										color: $blue;
										@include transition(80mns);
										@media screen and (max-width:$width - 1px){
											padding: 12px 15px;
										}
										&:hover{
											background: $green;
											color: #FFF;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&__contact{
			@media screen and (max-width:$width - 1px){
				position: fixed;
				left: 0;
				bottom: 0;
				width: 100%;
			}
			ul{
				@extend .clearfix;
				li{
					list-style: none;
					float: left;
					margin: 0 24px 0 0;
					@media screen and (max-width:$width - 1px){
						width: 50%;
						margin: 0;
					}
					&:last-child{
						margin: 0;
					}
					&.tel{
						dl{
							font-weight: 700;
							line-height: 1;
							color: $blue;
							margin: 18px 0 0;
							@media screen and (max-width:1080px){
								margin: 10px 0 0;
							}
							@media screen and (max-width:$width - 1px){
								margin: 0;	
							}
							dt{
								font-size: 10px;
								margin: 0 0 8px;
								@media screen and (max-width:1080px){
									line-height: 1.4;
								}
								br{
									display: none;
									@media screen and (max-width:1080px){
										display: inline;
									}
								}
								@media screen and (max-width:$width - 1px){
									display: none;
								}
							}
							dd{
								font-size: 28px;
								@media screen and (max-width:1080px){
									font-size: 20px;
								}
								@media screen and (max-width:$width - 1px){
									font-size: 13px;
								}
								i{
									margin: 0 8px 0 0;
									display: inline-block;
									transform: rotateY(180deg);
								}
								a{
									color: $light-blue;
									@media screen and (max-width:$width - 1px){
										background: $light-blue;
										color: #FFF;
										display: block;
										text-align: center;
										padding: 15px;
										line-height: 1.5;
										&:hover{
											color: #FFF;
										}
									}
									&:hover{
										color: $green;
									}
								}
							}
						}
					}
					&.contact{
						a{
							height: $pc-head - $h1;
							padding: 8px;
							background: $green;
							color: #FFF;
							@include flex();
							justify-content: center;
							align-items: center;
							font-size: 12px;
							line-height: 1.5;
							text-align: center;
							.sp{
								display: none;
								@media screen and (max-width:$width - 1px){
									display: inline-block;
									margin: 0 8px 0 0;
									font-size: 13px;
									width: auto;
								}
							}
							@media screen and (max-width:$width - 1px){
								display: block;
								height: auto;
								padding: 15px;
								font-size: 13px;
								font-weight: 700;
								br{
									display: none;
								}
							}
							&:hover{
								background: $light-blue;
							}
							i{
								display: block;
								width: 100%;
								font-size: 22px;
								@media screen and (max-width:$width - 1px){
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}