.site-flow{
	.flow{
		li{
			list-style: none;
			background: #FFF;
			margin: 0 0 56px;
			padding: 24px;
			position: relative;
			@media screen and (max-width:$width - 1px){
				margin: 0 0 32px;
				padding: 15px;
			}
			&.green{
				background: $green;
				color: #FFF;
				text-align: center;
				font-size: 18px;
				font-weight: 700;
				&:before{
					border-color: $green transparent transparent transparent;
				}
			}
			&:before{
				content: '';
                width: 0;
                height: 0;
				position: absolute;
				left: 50%;
				bottom: -24px;
                border-style: solid;
                border-width: 32px 32px 0 32px;
                border-color: #FFF transparent transparent transparent;
				transform: translateX(-50%);
			}
			&:last-child{
				margin: 0;
				&:before{
					display: none;
				}
			}
			figure{
				width: 30%;
				float: left;
				@media screen and (max-width:$width - 1px){
					width: auto;
					float: none;
					margin: 0 0 16px;
				}
				img{
					width: 100%;
				}
			}
			dl{
				width: 70%;
				padding: 0 0 0 32px;
				float: right;
				@media screen and (max-width:$width - 1px){
					width: auto;
					padding: 0;
				}
				dt{
					color: #FFF;
					background: $green;
					padding: 3px 16px;
					font-size: 17px;
					font-weight: 700;
					margin: 0 0 16px;
					@media screen and (max-width:$width - 1px){
						font-size: 15px;
					}
				}
			}
		}
	}
}
