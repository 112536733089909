.site-archive{
	.archive-list{
		li{
			background: #FFF;
			box-shadow: 0 0 16px rgba(#000,.12);
			margin: 0 0 32px;
			&:last-child{
				margin: 0;
			}
			.bg{
				width: 100%;
				height: 200px;
				display: block;
				background-size: cover;
				background-position: center;
				@media screen and (max-width:$width - 1px){
					height: 140px;
				}
				&:hover{
					opacity: .88;
				}
			}
			dl{
				padding: 24px;
				@media screen and (max-width:$width - 1px){
					padding: 15px;
				}
				dt{
					font-size: 20px;
					font-weight: 700;
					margin: 0 0 4px;
				}
				dd{
					&.more{
						margin: 24px 0 0;
						i{
							display: inline-block;
							margin: 0 0 0 8px;
							opacity: .32;
						}
					}
				}
			}
			.date{
				border-top: $border 1px solid;
				padding: 12px 24px;
			}
		}
	}
	.detail-box{
		background: #FFF;
		padding: 24px;
		border-radius: 3px;
		box-shadow: 4px 4px 0 rgba(#000,.12);
		position: relative;
		@media screen and (max-width:$width - 1px){
			padding: 15px;
		}
		h3{
			font-size: 20px;
			font-weight: 700;
			margin: 0 0 16px;
			padding: 0 100px 16px 0;
			border-bottom: rgba($purple,.24) 1px solid;
			@media screen and (max-width:$width - 1px){
				font-size: 18px;
				padding: 0 0 16px;
			}
			i{
				color: $green;
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
		.date{
			position: absolute;
			top: 30px;
			right: 24px;
			font-size: 12px;
			color: $purple;
			@media screen and (max-width:$width - 1px){
				position: relative;
				top: 0;
				left: 0;
				margin: 0 0 16px;
				display: block;
			}
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
		.detail-inner{
			img{
				max-width: 100%;
			}
		}
		.arrows{
			margin: 32px 0 0;
			ul{
				position: relative;
				border-top: rgba(#000,.12) 1px solid;
				border-bottom: rgba(#000,.12) 1px solid;
				padding: 16px 0;
				&:before{
					content: '';
					width: 1px;
					height: 60%;
					background: rgba(#000,.12);
					position: absolute;
					top: 20%;
					left: 50%;
				}
				li{
					float: left;
					width: 50%;
					&:nth-child(2){
						float: right;
						i{
							float: right;
							margin: 0 0 0 16px;
						}
						.txt{
							float: right;
							text-align: right;
						}
					}
					a{
						@extend .clearfix;
					}
					i{
						float: left;
						font-size: 32px;
						color: darken($glay,60%);
						opacity: .16;
						margin: 0 16px 0 0;
					}
					.txt{
						float: left;
						font-size: 11px;
						line-height: 1.5;
						.title{
							color: $normal;
						}
					}
				}
			}
		}
	}
	.minilist{
		li{
			list-style: none;
			margin: 0 0 16px;
			padding: 0 0 16px;
			border-bottom: rgba(#000,.16) 1px dotted;
			&:last-child{
				margin: 0;
				padding: 0;
				border: none;
			}
			a{
				display: block;
				@extend .clearfix;
			}
			.bg{
				width: 80px;
				height: 56px;
				background-position: center;
				background-size: cover;
				float: left;
				margin: 0 16px 0 0;
			}
			dl{
				dt{
					color: $blue;
					margin: 4px 0 0;
				}
				dd{
					display: inline-block;
					background: $green;
					color: #FFF;
					padding: 2px 0;
					width: 96px;
					font-size: 12px;
					text-align: center;
				}
			}
		}
	}
	.social-link{
		margin: 32px 0 0;
		@extend .clearfix;
		li{
			list-style: none;
			float: left;
			margin: 0 8px 0 0;
			&:last-child{
				margin: 0;
			}
			a{
				$size:48px;
				width: $size;
				height: $size;
				line-height: $size;
				text-align: center;
				display: block;
				color: #FFF;
				font-size: 20px;
				&.twitter{ background: #52a7e7; }
				&.facebook{ background: #41548f; }
				&:hover{
					opacity: .64;
				}
			}
		}
	}
}