.site-works{
	.flexer{
		@include flex();
		justify-content: flex-start;
		@media screen and (max-width:$width - 1px){
			justify-content: space-between;	
		}
		li{
			list-style: none;
			box-shadow: 8px 8px 0 rgba(#000,.32);
			@media screen and (min-width:$width){
				margin: 0 40px 40px 0;
				&:nth-child(4n+4){
					margin-right: 0;
				}
			}
			@media screen and (max-width:$width - 1px){
				margin: 0 0 24px;
			}
			.bg{
				width: 200px;
				height: 200px;
				display: block;
				background-position: center;
				background-size: cover;
				position: relative;
				@include transition(200ms);
				@media screen and (max-width:$width - 1px){
					width: 160px;
					height: 160px;
				}
				&:hover{
					opacity: .88;
				}
				i{
					background: rgba(#000,.48);
					padding: 4px 8px;
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 2;
					font-size: 13px;
					color: #FFF;
					width: 100%;
				}
			}
		}
	}
	
	.detail{
		#images{
			width: 48%;
			float: left;
			cursor: pointer;
			@media screen and (max-width:$width - 1px){
				width: auto;
				float: none;
				margin: 0 0 24px;
			}
			.bg{
				width: 100%;
				height: 280px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				@media screen and (max-width:$width - 1px){
					height: 200px;	
				}
			}
		}
		.vs{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 9999;
			@include flex();
			align-items: center;
			justify-content: center;
			@include transition(400ms);
			&.is-active{
				background: rgba(#000,.88);
				*{
					opacity: 1;
				}
			}
			*{
				opacity: 0;
			}
			img{
				max-width: 72%;
				max-height: 72%;
				width: auto;
				height: auto;
				@media screen and (max-width:$width - 1px){
					max-width: 88%;	
					max-height: 88%;	
				}
			}
			.close{
				$size: 48px;
				width: $size;
				height: $size;
				position: absolute;
				top: 24px;
				right: 24px;
				cursor: pointer;
                &:hover{
					&:before,
					&:after{
						transform: rotate(0deg);
					}
                }
				&:before,
				&:after{
					content: '';
					width: 100%;
					height: 1px;
					background: #FFF;
					position: absolute;
					top: 50%;
					left: 0;
					transform: rotate(45deg);
					@include transition(120ms);
				}
				&:after{
					transform: rotate(-45deg);
				}
			}
		}
		.data{
			width: 48%;
			float: right;
			@media screen and (max-width:$width - 1px){
				width: auto;
				float: none;
				margin: 24px 0 0;
			}
			dt{
				background: $purple;
				color: #FFF;
				text-align: center;
				font-size: 16px;
				padding: 2px 0;
				margin: 0 0 16px;
			}
			.meta{
				margin: 24px 0 0;
				padding: 24px 0 0;
				border-top: $light-green 1px solid;
				li{
					list-style: none;
					float: left;
					margin: 0 24px 0 0;
					&:last-child{
						margin: 0;
					}
					i{
						color: $green;
						display: inline-block;
						margin: 0 12px 0 0;
					}
				}
			}
		}
	}
	.arrows{
		padding: 48px 0;
		@media screen and (max-width:$width - 1px){
			padding: 24px 15px;
		}
		ul{
			position: relative;
			border-top: rgba(#000,.12) 1px solid;
			border-bottom: rgba(#000,.12) 1px solid;
			padding: 16px 0;
			&:before{
				content: '';
				width: 1px;
				height: 60%;
				background: rgba(#000,.12);
				position: absolute;
				top: 20%;
				left: 50%;
			}
			li{
				float: left;
				width: 50%;
				&:nth-child(2){
					float: right;
					i{
						float: right;
						margin: 0 0 0 16px;
					}
					.txt{
						float: right;
						text-align: right;
					}
				}
				a{
					@extend .clearfix;
				}
				i{
					float: left;
					font-size: 32px;
					color: darken($glay,60%);
					opacity: .8;
					margin: 0 16px 0 0;
				}
				.txt{
					float: left;
					font-size: 11px;
					line-height: 1.5;
					.title{
						color: $normal;
					}
				}
			}
		}
	}
}
